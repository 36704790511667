import { VuexModule, Module, Mutation, Action, getModule } from 'vuex-module-decorators';
import store from '@/store';

export interface ParamStore {
  selectMaterial: any
  friendTag: any
}

@Module({dynamic: true, store, name: 'param'})
class Param extends VuexModule implements ParamStore {
  @Mutation
  private CHANGE_SELECT_MATERIAL(data: any) {
    this.selectMaterial = data
  }

  @Action({ commit: 'CHANGE_SELECT_MATERIAL' })
  public changeSelectMaterial(data: any) {
    return data
  }

  @Mutation
  private CHANGE_FRIEND_TAG(data: any) {
    this.friendTag = data
  }

  @Action({ commit: 'CHANGE_FRIEND_TAG' })
  public changeFriendTag(data: any) {
    return data
  }

  public selectMaterial: any = null
  public friendTag: any = null
}

export const ParamModule = getModule(Param)
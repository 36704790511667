









import {
  Component,
  Model,
  Prop,
  Vue,
  Watch
} from "vue-property-decorator";

@Component({
  name: 'global-radio',
})
export default class GlobalRadio extends Vue {
  @Prop({}) private list!: any
  @Model('change') select!: number
  private activeV = 0

  @Watch('select')
  private onChangeSelect(val: number) {
    this.activeV = val
  }

  /**
   * @func 点击radio
   */
  private clickTab(id: number) {
    if (id === this.activeV) return
    this.activeV = id
    this.$emit('change', id)
  }

}

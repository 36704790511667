



























































































  import {
    Component,
    Vue
  } from "vue-property-decorator";
  import GlobalQiniu from '@/components/global/qiniu.vue'
  import GlobalRadio from '@/components/global/radio.vue'
  import {
    WechatModule
  } from "@/store/modules/wechat";
import { api } from "@/api";
import { ParamModule } from "@/store/modules/param";

  enum fileTypeEnum {
    image,
    video
  }

  @Component({
    name: 'create-moment',
    components: {
      'global-qiniu': GlobalQiniu,
      'global-radio': GlobalRadio
    }
  })
  export default class CreateMoment extends Vue {
    private imgList: string[] = []
    private fileTypeList: any[] = [{
      label: '图片',
      value: 0
    }, {
      label: '视频',
      value: 2
    }]
    private fileType: fileTypeEnum = fileTypeEnum.image
    private sendTypeList: any[] = [{
      label: '立即发送',
      value: 0
    }, {
      label: '定时发送',
      value: 1
    }]
    private sendType: number = 0;

    private taskData: any = {
      task_name: '',
      txt: '',
      interval_time: 1,
      comment_interval_time: 5,
    }
    private commontList: string[] = ['']
    private timing: any = {
      day: '',
      hour: 9,
      min: 0
    }
    private isClick = false

    private get wxId(): number {
      return WechatModule.wxId
    }

    private get selectMaterial(): any {
      return ParamModule.selectMaterial
    }

    private mounted() {
      if (this.selectMaterial) {
        this.taskData.task_name = this.selectMaterial.name
        this.taskData.txt = this.selectMaterial.documents
        this.imgList = this.selectMaterial.url
        if(this.selectMaterial.material_type === 1) {
          this.fileType = 0
        } else if (this.selectMaterial.material_type === 2) {
          this.fileType = 2
        }
      }
    }

    private beforeDestroy() {
      ParamModule.changeSelectMaterial(null)
    }

    /**
     * @func 图片上传回调
     * @param url string 上传文件连接
     */
    private uploadSuc(url: string) {
      if (this.imgList.length === 9) {
        this.$weui.topTips('上传文件不能超9个')
        return
      }
      this.imgList.push(url)
    }

    /**
     * @func 删除已经上传的图片或者视频
     * @param idx number 列表中的序号
     */
    private delImg(idx: number) {
      this.imgList.splice(idx, 1)
    }

    /**
     * @func 修改上传类型
     * @param type
     */
    private changeFileType(type: number) {
      this.imgList = []
      this.fileType = type
    }

    /**
     * @func 修改发送时间类型
     */
    private changeSendType(type: number) {
      this.sendType = type
    }

    /**
     * @func 添加评论
     */
    private addComment() {
      this.commontList.push('')
    }

    /**
     * @func 删除评论
     * @param index
     */
    private delComment(index: number) {
      this.commontList.splice(index, 1)
    }

    /**
     * @func 跳转到素材库
     */
    private toMaterial() {
      this.$router.push({
        name: 'Material'
      })
    }

    /**
     * @func 检查时间
     */
    private checkTime(type: string) {
      if (type === 'hour') {
        if (this.timing.hour < 0 || this.timing.hour >= 24) {
          this.timing.hour = ''
        }
      }
      if (type === 'min') {
        if (this.timing.min < 0 || this.timing.min >= 60) {
          this.timing.min = ''
        }
      }

    }

    /**
     * @func 设置定时时间
     */
    private setTiming() {
      let today = new Date()
      this.$weui.datePicker({
        start: 2021,
        end: 2030,
        defaultValue: [today.getFullYear(), today.getMonth() + 1, today.getDate()],
        onChange: (result: any) => {
          console.log(result)
        },
        onConfirm: (result: any) => {
          let day = `${result[0].value}/${result[1].value}/${result[2].value}`
          this.timing.day = day
        },
        id: 'datePicker'
      })
    }

    /**
     * @func 判断提交内容是否为空
     */
    private isEmpty() {
      if (!this.taskData.task_name) {
        this.$weui.topTips('任务名不能为空')
        return true
      }
      if (!this.taskData.txt) {
        this.$weui.topTips('内容不能为空')
        return true
      }
      if (this.sendType && !this.timing.day) {
        this.$weui.topTips('请选择定时时间')
        return true
      }
      if (this.sendType) {
        if (new Date(this.getTiming()).getTime() < new Date().getTime()) {
          this.$weui.topTips('定时时间不能小于当前时间')
          return true
        }
      }
      return false
    }

    /**
     * @func 转换时间
     */
    private getTiming() {
      let timingTime = this.timing.day + ' ' + this.timing.hour.toString().padStart(2, '0') + ':' + this.timing.min
        .toString().padStart(2, '0') + ':00'
      return timingTime
    }

    /**
     * @func 确认提交
     */
    private confirmSubmit() {
      this.$weui.confirm('确认提交信息?',
        () => {
          this.submit()
        },
        () => {
          console.log('取消')
        }, {
          title: '提示'
        }
      )
    }

    /**
     * @func 提交信息
     */
    private submit() {
      if (this.isEmpty()) {
        return
      }
      if (this.isClick) return
      this.isClick = true

      let selectRotot: any = [{
        wechat_id: this.wxId,
        visible: 0,
        tags: []
      }]

      const params: any = {
        robot_list: selectRotot,
        task_name: this.taskData.task_name,
        task: {
          task_time: !+this.sendType ? 0 : new Date(this.getTiming()).getTime() / 1000
        },
        interval_time: +this.taskData.interval_time,
        comment_interval_time: +this.taskData.comment_interval_time * 60,
        associated_task: 0
      }

      const commonts: any = []
      if (this.commontList.length >= 1 && this.commontList[0]) {
        for (let i = 0; i < this.commontList.length; i++) {
          if (this.commontList[i] !== '' && this.commontList[i]) {
            commonts.push(this.commontList[i])
          }
        }
      }

      if (!this.fileType) {
        params.task.task_sns = {
          task_type: +this.fileType,
          send: {
            txt: this.taskData.txt,
            pics: this.imgList
          },
          comment: {
            task_id: 0,
            comment: commonts
          }
        }
      }
      if (+this.fileType === 2) {
        params.task.task_sns = {
            task_type: +this.fileType,
            send_video: {
              txt: this.taskData.txt,
              video: this.imgList[0],
              pic: this.imgList[0] + '?vframe/jpg/offset/1'
            },
            comment: {
              task_id: 0,
              comment: commonts
            }
          }
      }

      this.requestcreate(params)
    }

    /**
     * @func 创建请求
     * @param params 参数
     */
    private async requestcreate(params: any) {
      try {
        await api.doTask(params)
        this.$weui.topTips('创建成功', {
          className: 'success-tips'
        })
        this.isClick = false
        this.$router.go(-1)
      } catch(err) {
        console.log(err)
        this.isClick = false
      }
    }

  }
